var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-2",attrs:{"flat":"","height":"100%"}},[_c('v-card-title',{staticClass:"text-color-title heading font-weight-bold mb-5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-tabs',{attrs:{"color":"cyan"}},[_vm._l((_vm.times),function(time,key){return [_c('v-tab',{key:key,staticClass:"subtitle-2 text-capitalize black--text font-weight-bold px-0",on:{"click":function($event){return _vm.setTime(time.value)}}},[_vm._v(" "+_vm._s(time.text)+" ")])]})],2)],1),_c('div',{key:_vm.tableKey,ref:"devotionTable",staticClass:"mt-10"},[_c('v-data-table',{staticClass:"rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"item-class":_vm.itemRowBackground,"server-items-length":_vm.totalItems,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.paginate]},scopedSlots:_vm._u([{key:"item.scheduled_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("MIXINS_DATE")(item.scheduled_at))+" ")])]}},{key:"item.verse",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(item.verse)+" ")])]}},{key:"item.verse_text",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption d-block text-truncate",staticStyle:{"width":"400px"}},[_vm._v(" "+_vm._s(item.verse_text)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-1",attrs:{"rounded":"","small":"","depressed":"","dark":"","color":"cyan"},on:{"click":function($event){_vm.sendDetails(item, _vm.items.indexOf(item))}}},[_vm._v(" Edit ")]),_c('delete-item-dialog',{attrs:{"itemID":item.id,"setup":_vm.setupDeleteDialog,"deleteURL":_vm.componentURL,"event-name":_vm.eventName}})],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }